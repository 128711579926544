import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TrialAccountGuard } from 'app/guards/trial-account.guard';
import { of } from 'rxjs';
import { RouteSegment } from './enums/route-segments.enum';
import { ExpiredUserGuard } from './guards/expired-user.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { NotExpiredUserGuard } from './guards/not-expired-user.guard';
import { NotLoggedInGuard } from './guards/not-logged-in.guard';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { BaseLayoutComponent } from './layouts/base/base-layout.component';
import { LoginModule } from './pages/login/login.module';

const routes: Routes = [
    {
        path: '',
        component: BaseLayoutComponent,
        pathMatch: 'full',
        children: [
            {
                path: RouteSegment.Root,
                loadChildren: () => of(LoginModule),
                canLoad: [NotLoggedInGuard],
                canActivate: [NotLoggedInGuard]
            }
        ]
    },
    {
        path: '',
        component: BaseLayoutComponent,
        children: [
            {
                path: RouteSegment.Register,
                loadChildren: () =>
                    import('./pages/register/register.module').then((m) => m.RegisterModule),
                canLoad: [NotLoggedInGuard],
                canActivate: [NotLoggedInGuard]
            }
        ]
    },
    {
        path: '',
        component: BaseLayoutComponent,
        children: [
            {
                path: RouteSegment.ThankYou,
                loadChildren: () =>
                    import('./pages/thank-you/thank-you.module').then((m) => m.ThankYouModule),
                canLoad: [NotLoggedInGuard],
                canActivate: [NotLoggedInGuard]
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: RouteSegment.Profile,
                loadChildren: () =>
                    import('./pages/profile-page/profile-page.module').then(
                        (m) => m.ProfilePageModule
                    ),
                canLoad: [LoggedInGuard],
                canActivate: [LoggedInGuard]
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: RouteSegment.ContactPage,
                loadChildren: () =>
                    import('./pages/contact-page/contact-page.module').then(
                        (m) => m.ContactPageModule
                    ),
                canLoad: [LoggedInGuard],
                canActivate: [LoggedInGuard]
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: RouteSegment.Dashboard,
                loadChildren: () =>
                    import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
                canLoad: [LoggedInGuard, NotExpiredUserGuard],
                canActivateChild: [LoggedInGuard, NotExpiredUserGuard],
                canActivate: [LoggedInGuard, NotExpiredUserGuard]
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: RouteSegment.MapView,
                loadChildren: () =>
                    import('./pages/map-view/map-view.module').then((m) => m.MapViewModule),
                canLoad: [LoggedInGuard, NotExpiredUserGuard],
                canActivateChild: [LoggedInGuard, NotExpiredUserGuard],
                canActivate: [LoggedInGuard, NotExpiredUserGuard]
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: RouteSegment.CompareTables,
                loadChildren: () =>
                    import('./pages/compare-tables/compare-tables.module').then(
                        (m) => m.CompareTablesModule
                    ),
                canLoad: [LoggedInGuard, NotExpiredUserGuard],
                canActivateChild: [LoggedInGuard, NotExpiredUserGuard],
                canActivate: [LoggedInGuard, NotExpiredUserGuard]
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: RouteSegment.Admin,
                loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
                canLoad: [LoggedInGuard, NotExpiredUserGuard],
                canActivateChild: [LoggedInGuard, NotExpiredUserGuard],
                canActivate: [LoggedInGuard, NotExpiredUserGuard]
            }
        ]
    },
    {
        path: '',
        component: BaseLayoutComponent,
        children: [
            {
                path: RouteSegment.ForgotPassword,
                loadChildren: () =>
                    import('./pages/forgot-password/forgot-password.module').then(
                        (m) => m.ForgotPasswordModule
                    ),
                canLoad: [NotLoggedInGuard],
                canActivate: [NotLoggedInGuard]
            }
        ]
    },
    {
        path: '',
        component: BaseLayoutComponent,
        children: [
            {
                path: RouteSegment.ResetPassword,
                loadChildren: () =>
                    import('./pages/reset-password/reset-password.module').then(
                        (m) => m.ResetPasswordModule
                    ),
                canLoad: [NotLoggedInGuard],
                canActivate: [NotLoggedInGuard]
            }
        ]
    },
    {
        path: RouteSegment.DraftPhaseCreation,
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./pages/draft-phase-creation/draft-phase-creation.module').then(
                        (m) => m.DraftPhaseCreationModule
                    ),
                canLoad: [LoggedInGuard, TrialAccountGuard, NotExpiredUserGuard],
                canActivateChild: [LoggedInGuard, TrialAccountGuard, NotExpiredUserGuard],
                canActivate: [LoggedInGuard, TrialAccountGuard, NotExpiredUserGuard]
            },
            { path: '', redirectTo: '', pathMatch: 'full' }
        ]
    },
    {
        path: RouteSegment.TrialDraftPhaseCreation,
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './pages/trial-draft-phase-creation/trial-draft-phase-creation.module'
                    ).then((m) => m.TrialDraftPhaseCreationModule),
                canLoad: [LoggedInGuard, NotExpiredUserGuard],
                canActivateChild: [LoggedInGuard, NotExpiredUserGuard],
                canActivate: [LoggedInGuard, NotExpiredUserGuard]
            }
        ]
    },
    {
        path: RouteSegment.ExpiredFeedback,
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./pages/expired-feedback/expired-feedback.module').then(
                        (m) => m.ExpiredFeedbackModule
                    ),
                canLoad: [LoggedInGuard, ExpiredUserGuard],
                canActivateChild: [LoggedInGuard, ExpiredUserGuard],
                canActivate: [LoggedInGuard, ExpiredUserGuard]
            }
        ]
    },
    {
        path: RouteSegment.PreviewSummary,
        component: BaseLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./pages/preview-summary/preview-summary.module').then(
                        (m) => m.PreviewSummaryModule
                    )
                // canLoad: [NotExpiredUserGuard],
                // canActivateChild: [NotExpiredUserGuard],
                // canActivate: [NotExpiredUserGuard]
                // canLoad: [NotLoggedInGuard],
                // canActivate: [NotLoggedInGuard]
            }
        ]
    },
    {
        path: RouteSegment.ContactUsPopup,
        component: BaseLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./pages/contact-us-popup/contact-us-popup.module').then(
                        (m) => m.ContactUsPopupModule
                    ),
                canLoad: [NotExpiredUserGuard],
                canActivateChild: [NotExpiredUserGuard],
                canActivate: [NotExpiredUserGuard]
            }
        ]
    },
    {
        path: '',
        component: BaseLayoutComponent,
        children: [
            // Fallback when no prior route is matched
            {
                path: '**',
                pathMatch: 'full',
                loadChildren: () =>
                    import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule)
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabledBlocking'
        })
    ],
    exports: [RouterModule],
    providers: [LoggedInGuard, NotLoggedInGuard]
})
export class AppRoutingModule {}
