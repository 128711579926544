import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-header-dropdown',
    templateUrl: './header-dropdown.component.html',
    styleUrls: ['./header-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderDropdownComponent {
    @Input() public dropdownButtonTitle!: string;
    @Input() public dropdownButtonImgSrc!: string;
    @Input() public dropdownTitle!: string;
    @Input() public dropdownTitleIsActive: boolean = true;
    @Output() dropdownIsOpen = new EventEmitter<boolean>();

    public listIsOpen = false;

    @ViewChild('togglerButton') togglerButton!: ElementRef<HTMLElement>;
    private onDestroy$ = new Subject<void>();

    constructor(private readonly elementRef: ElementRef, private readonly cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        fromEvent<MouseEvent>(document.body, 'click')
            .pipe(
                filter(() => this.listIsOpen),
                filter((event) => {
                    const clickTarget = event.target as HTMLElement;
                    const needToCloseList =
                        !this.elementRef.nativeElement.contains(clickTarget) &&
                        clickTarget !== this.togglerButton?.nativeElement &&
                        !this.togglerButton?.nativeElement?.contains(clickTarget);

                    return needToCloseList;
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => {
                this.closeList('');
            });
    }

    public toggleListIsOpen(): void {
        this.listIsOpen = !this.listIsOpen;
        this.dropdownIsOpen.emit(this.listIsOpen);
        this.cdr.markForCheck();
    }

    public closeList(selectedItem: string): void {
        this.listIsOpen = false;
        // Do something with the selected item...
        this.cdr.markForCheck();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
