<app-payment-popup [showPopup]="changingValue" [initShowPopup]="false"></app-payment-popup>
<div
    class="relative flex min-h-[var(--header-height)] w-full items-stretch justify-between gap-4 bg-white px-[30px] lg:p-[10px] md:p-0"
    *ngLet="{
        activeLanguageCode: activeLanguageCode$ | async,
        userState: store.select(selectUserState) | async
    } as ctx"
>
    <button
        (click)="toggleMainMenu()"
        class="!hidden !h-auto shrink-0 cursor-pointer items-center text-brand2 md:!flex"
        mat-button
    >
        <mat-icon
            svgIcon="keystone-logo-v2"
            style="shape-rendering: auto"
            class="!mx-3 !h-[50px] !w-[60px] cursor-pointer !text-brand2"
        ></mat-icon>
    </button>

    <div class="flex-1 self-center px-4 md:px-0 md:text-center">
        <a
            *ngFor="let item of linkTree$ | async; let last = last"
            [routerLink]="item.route"
            [ngClass]="{ 'text-darkgray': last, 'text-gray5': !last }"
            [class.disabled]="last ? true : null"
            class="cursor-pointer text-md font-medium text-gray1"
        >
            <span *ngIf="item.text" class="text-brand1"> {{ item.text }}</span>
            <span *ngIf="item.translations$">{{ item.translations$ | async }}</span>
            <span *ngIf="!last"> / </span>
        </a>
    </div>

    <div class="flex">
        <div
            *ngIf="ctx!.userState!.accountType === AccountType.Trial"
            class="btn-primary mx-auto my-8 flex cursor-pointer items-center gap-2 md:hidden"
            (click)="onClickShowPopup()"
        >
            <p>{{ 'expire-feedback.upragde-to-premium' | transloco }}</p>
        </div>

        <!-- mobile menu -->
        <button
            (click)="toggleUserMenu()"
            mat-button
            class="!hidden !h-auto cursor-pointer !justify-start md:!flex"
        >
            <div class="flex items-center justify-between gap-4 px-4">
                <div class="flex items-center gap-4">
                    <div class="relative flex w-12 shrink-0">
                        <img
                            [src]="getUserProfileImage(ctx!.userState!)"
                            class="h-12 rounded-full"
                        />
                        <mat-icon
                            *ngIf="ctx!.userState!.isAdmin"
                            class="absolute bottom-[-6px] right-0"
                            svgIcon="kid_star"
                        ></mat-icon>
                    </div>
                </div>
            </div>
        </button>

        <!-- large menu -->
        <button
            (menuOpened)="menuOpen = true"
            (menuClosed)="menuOpen = false"
            [matMenuTriggerFor]="menu"
            mat-button
            class="flex !h-auto cursor-pointer !justify-start md:!hidden"
        >
            <div class="ml-4 flex items-center justify-between gap-4">
                <div class="flex items-center gap-4">
                    <div class="relative flex w-12 shrink-0">
                        <img
                            [src]="getUserProfileImage(ctx!.userState!)"
                            class="h-12 rounded-full"
                        />
                        <mat-icon
                            *ngIf="ctx!.userState!.isAdmin"
                            class="absolute bottom-[-6px] right-0"
                            svgIcon="kid_star"
                        ></mat-icon>
                    </div>
                    <ng-container>
                        <div class="w-[200px] text-left">
                            <p class="text-md capitalize">
                                {{ ctx!.userState!.firstName + ' ' + ctx!.userState!.lastName }}
                            </p>
                            <p class="flex items-center gap-4 text-brand1">
                                <ng-container
                                    *ngIf="!userAccountTypeIsUnlimited; else unLimitedUser"
                                >
                                    {{
                                        ctx!.userState!.isAdmin || userAccountTypeIsPremium
                                            ? ('menu.is-premium-text' | transloco)
                                            : ('menu.is-not-premium-text' | transloco)
                                    }}
                                </ng-container>
                                <ng-template #unLimitedUser>
                                    {{ 'menu.is-unlimited-text' | transloco }}
                                </ng-template>
                                <span
                                    *ngIf="!ctx!.userState!.isAdmin && !userAccountTypeIsUnlimited"
                                    class="h-[8px] w-[8px] rounded-full bg-red"
                                    [matTooltip]="
                                        ('menu.end-of-subscription' | transloco) +
                                        userAccountValidToDate
                                    "
                                >
                                </span>
                            </p>
                        </div>
                    </ng-container>
                </div>
                <mat-icon
                    svgIcon="arrow"
                    [ngClass]="{ open: menuOpen }"
                    class="arrow mr-[14px] !w-3"
                ></mat-icon>
            </div>
        </button>
        <mat-menu #menu="matMenu" yPosition="below" class="menu !w-[320px] !rounded-sm !p-3">
            <a
                [routerLink]="['', RouteSegment.Profile]"
                mat-menu-item
                class="!mb-3 !min-h-[32px] !px-0"
            >
                <div class="flex flex-col">
                    <div class="flex gap-4">
                        <img
                            [src]="getUserProfileImage(ctx!.userState!)"
                            class="h-12 w-12 rounded-full"
                        />
                        <div class="flex flex-col">
                            <p class="text-darkgray">
                                {{ ctx!.userState!.firstName + ' ' + ctx!.userState!.lastName }}
                            </p>

                            <p class="text-gray1">{{ ctx!.userState!.email }}</p>
                        </div>
                    </div>
                </div>
            </a>

            <a
                mat-menu-item
                class="!mb-3 !min-h-[32px] !gap-4 !px-2"
                *ngIf="ctx!.userState!.accountType === AccountType.Trial"
                (click)="onClickShowPopup()"
            >
                <mat-icon class="!text-brand1" svgIcon="diamond-color"></mat-icon>
                <p class="text-brand1">{{ 'expire-feedback.upragde-to-premium' | transloco }}</p>
            </a>

            <a
                [routerLink]="['', RouteSegment.Profile]"
                mat-menu-item
                class="!mb-3 !min-h-[32px] !gap-4 !px-2"
            >
                <mat-icon svgIcon="profile"></mat-icon>
                {{ 'menu.profile' | transloco }}
            </a>
            <a
                *ngIf="userAccountTypeIsTrial"
                [routerLink]="['', RouteSegment.ContactPage, RouteSegment.ModifyContact]"
                mat-menu-item
                class="!mb-3 !min-h-[32px] !gap-4 !px-2"
            >
                <mat-icon svgIcon="profile"></mat-icon>
                {{ 'contact-list.name-list-modify' | transloco }}
            </a>

            <mat-accordion (click)="$event.stopPropagation()">
                <mat-expansion-panel
                    (opened)="languageSelectOpenState = true"
                    (closed)="languageSelectOpenState = false"
                >
                    <mat-expansion-panel-header class="!mb-3 !h-[32px] !px-2">
                        <mat-panel-title class="gap-4">
                            <mat-icon svgIcon="selected_language" class="color-"></mat-icon>
                            {{
                                ctx!.activeLanguageCode! === 'en'
                                    ? (selectedLanguageTitleEnglish$ | async)
                                    : (selectedLanguageTitleHungarian$ | async)
                            }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div
                        *ngFor="let lang of store.select(selectAvailableLanguages) | async"
                        [ngClass]="{
                            option: true,
                            optionIsActive: ctx!.activeLanguageCode === lang
                        }"
                        class="mb-3 flex h-[32px] items-center gap-4 px-2"
                        (click)="store.dispatch(setActiveLanguageCode({ languageCode: lang }))"
                    >
                        <img
                            [src]="'assets/imgs/icons/languages/' + lang + '.svg'"
                            class="mr-[2px] h-[22px] w-[22px] rounded-full"
                        />
                        {{
                            lang === 'en'
                                ? (selectedLanguageTitleEnglish$ | async)
                                : (selectedLanguageTitleHungarian$ | async)
                        }}
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <a (click)="onLogout()" mat-menu-item class="!min-h-[32px] !gap-4 !px-2">
                <mat-icon svgIcon="logout"></mat-icon>
                {{ 'menu.logout' | transloco }}</a
            >
        </mat-menu>
    </div>

    <div
        [@toggleMenu]="mainMenuVisible"
        class="dropdown-menu z-1000 absolute left-0 top-[var(--header-height)] w-full overflow-hidden bg-white shadow-card"
        *ngLet="store.select(selectUserState) | async as userState"
    >
        <div *ngFor="let linkOption of mainMenuOptions">
            <a
                *ngIf="!linkOption.userIsAdmin || (linkOption.userIsAdmin && userState?.isAdmin)"
                [routerLink]="
                    linkOption.link === RouteSegment.ContactUsPopup ? null : linkOption.link
                "
                (click)="onMenuItemClick(linkOption)"
                routerLinkActive="!border-brand1"
                class="flex flex-row items-center justify-start gap-8 border-r-4 border-l-4 border-white py-5 px-8 text-center hover:bg-primaryHover"
            >
                <mat-icon
                    [svgIcon]="linkOption.icon"
                    class="!h-[32px] !w-[32px] cursor-pointer text-brand1"
                ></mat-icon>
                <span class="text-black">{{ linkOption.text$ | async }}</span>
            </a>
        </div>
    </div>

    <div
        [@toggleMenu]="userMenuVisible"
        class="dropdown-menu z-1000 absolute left-0 top-[var(--header-height)] w-full overflow-hidden bg-white shadow-card"
        *ngLet="store.select(selectUserState) | async as userState"
    >
        <div
            (click)="onMenuItemClick(null)"
            [routerLink]="['', RouteSegment.Profile]"
            class="flex flex-row items-center justify-start gap-8 bg-primaryHover py-5 px-8"
        >
            <div class="flex flex-col">
                <div class="flex items-center gap-6">
                    <img
                        [src]="getUserProfileImage(ctx!.userState!)"
                        class="h-12 w-12 rounded-full"
                    />
                    <div class="flex flex-col">
                        <p class="text-md font-bold capitalize text-darkgray">
                            {{ ctx!.userState!.firstName + ' ' + ctx!.userState!.lastName }}
                        </p>

                        <p class="text-gray1">{{ ctx!.userState!.email }}</p>

                        <p class="flex items-center gap-4 text-brand1">
                            <ng-container *ngIf="!userAccountTypeIsUnlimited; else unLimitedUser">
                                {{
                                    ctx!.userState!.isAdmin || userAccountTypeIsPremium
                                        ? ('menu.is-premium-text' | transloco)
                                        : ('menu.is-not-premium-text' | transloco)
                                }}
                            </ng-container>
                            <ng-template #unLimitedUser>
                                {{ 'menu.is-unlimited-text' | transloco }}
                            </ng-template>
                            <!--                            <span-->
                            <!--                                *ngIf="!ctx!.userState!.isAdmin && !userAccountTypeIsUnlimited"-->
                            <!--                                class="h-[8px] w-[8px] rounded-full bg-red"-->
                            <!--                                [matTooltip]="-->
                            <!--                                    ('menu.end-of-subscription' | transloco) +-->
                            <!--                                    userAccountValidToDate-->
                            <!--                                "-->
                            <!--                            >-->
                            <!--                            </span>-->
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="flex flex-row items-center justify-start gap-8 border-r-4 border-l-4 border-white py-5 px-8 text-center hover:bg-primaryHover"
            *ngIf="ctx!.userState!.accountType === AccountType.Trial"
            (click)="onClickShowPopup()"
        >
            <mat-icon
                class="!h-[32px] !w-[32px] cursor-pointer !text-brand1"
                svgIcon="diamond-color"
            ></mat-icon>
            <p class="font-bold text-brand1">
                {{ 'expire-feedback.upragde-to-premium' | transloco }}
            </p>
        </div>

        <div *ngFor="let linkOption of userMenuItems">
            <a
                *ngIf="
                    (!linkOption.userIsAdmin && !linkOption.userIsPremium) ||
                    (linkOption.userIsAdmin && userState?.isAdmin) ||
                    (linkOption.userIsPremium && userState?.accountType !== AccountType.Trial)
                "
                [routerLink]="
                    linkOption.link === RouteSegment.ContactUsPopup ? null : linkOption.link
                "
                (click)="onMenuItemClick(linkOption)"
                routerLinkActive="!border-brand1"
                class="flex flex-row items-center justify-start gap-8 border-r-4 border-l-4 border-white py-5 px-8 text-center hover:bg-primaryHover"
            >
                <mat-icon
                    [svgIcon]="linkOption.icon"
                    class="!h-[32px] !w-[32px] cursor-pointer text-brand1"
                ></mat-icon>
                <span class="text-black">{{ linkOption.text$ | async }}</span>
            </a>
        </div>

        <mat-accordion (click)="$event.stopPropagation()">
            <mat-expansion-panel
                (opened)="languageSelectOpenState = true"
                (closed)="languageSelectOpenState = false"
            >
                <mat-expansion-panel-header class="!pl-0">
                    <mat-panel-title
                        class="flex flex-row items-center justify-start gap-8 py-5 px-9 text-center"
                    >
                        <mat-icon
                            svgIcon="selected_language"
                            class="!h-[32px] !w-[32px] cursor-pointer text-brand1"
                        ></mat-icon>
                        <span class="text-black">
                            {{
                                ctx!.activeLanguageCode! === 'en'
                                    ? (selectedLanguageTitleEnglish$ | async)
                                    : (selectedLanguageTitleHungarian$ | async)
                            }}
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                    *ngFor="let lang of store.select(selectAvailableLanguages) | async"
                    [ngClass]="{
                        option: true,
                        optionIsActive: ctx!.activeLanguageCode === lang
                    }"
                    class="flex flex-row items-center justify-start gap-8 border-r-4 border-l-4 border-white py-5 px-8 text-center hover:bg-primaryHover"
                    (click)="store.dispatch(setActiveLanguageCode({ languageCode: lang }))"
                >
                    <img
                        [src]="'assets/imgs/icons/languages/' + lang + '.svg'"
                        class="!h-[32px] !w-[32px] rounded-full"
                    />
                    <span class="text-black">
                        {{
                            lang === 'en'
                                ? (selectedLanguageTitleEnglish$ | async)
                                : (selectedLanguageTitleHungarian$ | async)
                        }}
                    </span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div
            (click)="onLogout()"
            class="flex flex-row items-center justify-start gap-8 border-r-4 border-l-4 border-white py-5 px-8 text-center hover:bg-primaryHover"
        >
            <mat-icon
                svgIcon="logout"
                class="!h-[32px] !w-[32px] cursor-pointer text-brand1"
            ></mat-icon>
            <span class="text-black"> {{ 'menu.logout' | transloco }}</span>
        </div>
    </div>
</div>
