import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { INavLinkOption } from 'app/models/ui/nav-link-option.model';
import { ContactUsPopupComponent } from 'app/pages/contact-us-popup/contact-us-popup.component';
import { BreakpointService } from 'app/services/breakpoint.service';
import { selectUserState } from 'app/store/user/user.selectors';

@Component({
    selector: 'app-menu-side-nav',
    templateUrl: './menu-side-nav.component.html',
    styleUrls: ['./menu-side-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSideNavComponent {
    @Input() navLinkOptions!: INavLinkOption[];
    @Output() navClick = new EventEmitter<INavLinkOption | null>();
    public selectUserState = selectUserState;
    public RouteSegment = RouteSegment;

    constructor(
        public readonly breakpointService: BreakpointService,
        public readonly store: Store,
        public dialog: MatDialog
    ) {}

    public openContactUsModal(): void {
        this.navClick.emit(null);
        this.dialog.open(ContactUsPopupComponent, {
            width: '450px'
        });
    }
}
