import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ICurrencyEntity } from '../../api/models/currency-entity';

@Pipe({
    name: 'totalCostFormatting'
})
export class TotalCostFormatting implements PipeTransform {
    constructor(
        private readonly decimalPipe: DecimalPipe // private readonly translocoService: TranslocoService
    ) {}

    transform(value: number, currency: ICurrencyEntity): string {
        if (!value || !currency) {
            return '';
        }

        if (currency?.name !== 'HUF') {
            return this.decimalPipe.transform(value, '1.0-0')!;
        }

        /* if (value >= 1000000) {
            return this.decimalPipe.transform(Math.round(value / 1000000), '1.0-0') + 'M'!;
        } else */ if (value >= 1000) {
            return `${this.decimalPipe.transform(Math.round(value / 1000), '1.0-0')},000`;
        } else {
            return this.decimalPipe.transform(value, '1.0-0')!;
        }
    }
}
