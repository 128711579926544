import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { selectActiveLanguageCode, selectAvailableLanguages } from '../../../store/language/language.selectors';
import { setActiveLanguageCode } from '../../../store/language/language.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSwitchComponent {

    @Input() public showLanguageName: boolean = true;

    protected readonly selectAvailableLanguages = selectAvailableLanguages;
    protected readonly setActiveLanguageCode = setActiveLanguageCode;

    constructor(        protected readonly store: Store) {
    }

    protected readonly selectActiveLanguageCode = selectActiveLanguageCode;
}
