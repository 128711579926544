import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RouteSegment } from 'app/enums/route-segments.enum';
import {
    getUserFailure,
    userLogin,
    userLoginFailure,
    userLoginSuccess
} from 'app/store/user/user.actions';
import {
    selectGetUserFailure,
    selectGetUserProgress,
    selectUserLoginFailure,
    selectUserLoginInProgress
} from 'app/store/user/user.selectors';
import { UserState } from 'app/store/user/user.state';
import { Observable, combineLatest } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { selectAvailableLanguages } from '../../store/language/language.selectors';
import { setActiveLanguageCode } from '../../store/language/language.actions';

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
    public isLoading$!: Observable<[boolean, boolean]>;
    public errorMessage$!: Observable<[string | null, string | null]>;
    public routeSegment = RouteSegment;

    get googleLogin() {
        return `${environment.apiUrl}/auth/google`;
    }

    get facebookLogin() {
        return `${environment.apiUrl}/auth/facebook`;
    }

    constructor(
        private formBuilder: FormBuilder,
        protected store: Store<UserState>,
        private route: ActivatedRoute
    ) {}

    public loginForm = this.formBuilder.group({
        email: ['', [Validators.email, Validators.required]],
        password: ['', [Validators.required]]
    });

    public ngOnInit(): void {
        this.store.dispatch(userLoginFailure({ error: '' }));
        this.store.dispatch(getUserFailure({ error: '' }));

        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
            console.log(params);
            if (params['token']) {
                this.store.dispatch(
                    userLoginSuccess({
                        accessToken: params['token']
                    })
                );
            }
        });

        this.isLoading$ = combineLatest([
            this.store.select(selectUserLoginInProgress),
            this.store.select(selectGetUserProgress)
        ]).pipe(untilDestroyed(this));

        this.errorMessage$ = combineLatest([
            this.store.select(selectUserLoginFailure),
            this.store.select(selectGetUserFailure)
        ]).pipe(untilDestroyed(this));
    }

    public onSubmit() {
        this.store.dispatch(
            userLogin({
                email: this.loginForm.value.email!,
                password: this.loginForm.value.password!
            })
        );
    }

    protected readonly selectAvailableLanguages = selectAvailableLanguages;
    protected readonly setActiveLanguageCode = setActiveLanguageCode;
}
