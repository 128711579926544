<app-header-dropdown
    *ngLet="store.select(selectActiveLanguageCode) | async as activeLanguageCode"
    [dropdownButtonImgSrc]="'assets/imgs/icons/languages/' + activeLanguageCode + '.svg'"
    [dropdownButtonTitle]="showLanguageName ? (activeLanguageCode! | uppercase) : ''"
>
    <div
        (click)="store.dispatch(setActiveLanguageCode({ languageCode: lang }))"
        *ngFor="let lang of store.select(selectAvailableLanguages) | async"
        [ngClass]="{
            option: true,
            optionIsActive: activeLanguageCode === lang
        }"
        class="flex gap-2"
    >
        <img [src]="'assets/imgs/icons/languages/' + lang + '.svg'" class="h-5" />
        {{ lang | uppercase }}
    </div>
</app-header-dropdown>
