<div
    class="dropdown-list absolute bottom-auto top-full right-0 w-full cursor-pointer rounded-[3px] bg-gray2 text-end"
    *ngIf="listIsOpen"
>
    <h2 class="py-0 px-[14px]" *ngIf="dropdownTitleIsActive">{{ dropdownTitle }}</h2>
    <div class="options">
        <ng-content></ng-content>
    </div>
</div>

<div
    (click)="toggleListIsOpen()"
    #togglerButton
    class="flex h-[32px] cursor-pointer justify-between gap-2 rounded-md bg-gray2 px-2 py-1"
>
    <div class="flex items-center gap-4">
        <img *ngIf="dropdownButtonImgSrc" [src]="dropdownButtonImgSrc" class="h-6 w-6 rounded-lg" />
        <span class="flex h-full items-center">{{ dropdownButtonTitle }}</span>
    </div>
    <div class="arrow-container relative h-5 w-5" [ngClass]="{ open: listIsOpen }">
        <div class="arrow absolute bottom-0 left-0 h-0 w-0"></div>
    </div>
</div>
