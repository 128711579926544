/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssetUploadDto } from '../models/asset-upload-dto';
import { CreateIfcFromTemplateDto } from '../models/create-ifc-from-template-dto';
import { CreateProjectDto } from '../models/create-project-dto';
import { DraftPhaseEntity } from '../models/draft-phase-entity';
import { FilterProjectPhasesDto } from '../models/filter-project-phases-dto';
import { IfcIdWithMessageEntity } from '../models/ifc-id-with-message-entity';
import { IfcMetadataEntity } from '../models/ifc-metadata-entity';
import { IfcTemplateEntity } from '../models/ifc-template-entity';
import { PaginationRequest } from '../models/pagination-request';
import { ProjectEntity } from '../models/project-entity';
import { UpdateProjectDto } from '../models/update-project-dto';

@Injectable({
    providedIn: 'root'
})
export class ProjectApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation createProject
     */
    static readonly CreateProjectPath = '/project/new';

    /**
     * Creates a new project.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createProject()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createProject$Response(params: {
        context?: HttpContext;
        body: CreateProjectDto;
    }): Observable<StrictHttpResponse<ProjectEntity>> {
        const rb = new RequestBuilder(this.rootUrl, ProjectApiService.CreateProjectPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ProjectEntity>;
                })
            );
    }

    /**
     * Creates a new project.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createProject$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createProject(params: {
        context?: HttpContext;
        body: CreateProjectDto;
    }): Observable<ProjectEntity> {
        return this.createProject$Response(params).pipe(
            map((r: StrictHttpResponse<ProjectEntity>) => r.body as ProjectEntity)
        );
    }

    /**
     * Path part for operation filterProjects
     */
    static readonly FilterProjectsPath = '/project';

    /**
     * Filters projects by user id, paginated.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterProjects()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterProjects$Response(params: { context?: HttpContext; body: PaginationRequest }): Observable<
        StrictHttpResponse<{
            total?: number;
            page?: number;
            perPage?: number;
            data?: Array<ProjectEntity>;
        }>
    > {
        const rb = new RequestBuilder(this.rootUrl, ProjectApiService.FilterProjectsPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<ProjectEntity>;
                    }>;
                })
            );
    }

    /**
     * Filters projects by user id, paginated.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterProjects$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterProjects(params: { context?: HttpContext; body: PaginationRequest }): Observable<{
        total?: number;
        page?: number;
        perPage?: number;
        data?: Array<ProjectEntity>;
    }> {
        return this.filterProjects$Response(params).pipe(
            map(
                (
                    r: StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<ProjectEntity>;
                    }>
                ) =>
                    r.body as {
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<ProjectEntity>;
                    }
            )
        );
    }

    /**
     * Path part for operation uploadIfc
     */
    static readonly UploadIfcPath = '/project/ifc';

    /**
     * Uploads an IFC file.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `uploadIfc()` instead.
     *
     * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
     */
    uploadIfc$Response(params: {
        context?: HttpContext;
        body: AssetUploadDto;
    }): Observable<StrictHttpResponse<IfcIdWithMessageEntity>> {
        const rb = new RequestBuilder(this.rootUrl, ProjectApiService.UploadIfcPath, 'post');
        if (params) {
            rb.body(params.body, 'multipart/form-data');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<IfcIdWithMessageEntity>;
                })
            );
    }

    /**
     * Uploads an IFC file.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `uploadIfc$Response()` instead.
     *
     * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
     */
    uploadIfc(params: {
        context?: HttpContext;
        body: AssetUploadDto;
    }): Observable<IfcIdWithMessageEntity> {
        return this.uploadIfc$Response(params).pipe(
            map((r: StrictHttpResponse<IfcIdWithMessageEntity>) => r.body as IfcIdWithMessageEntity)
        );
    }

    /**
     * Path part for operation createIfcFromTemplate
     */
    static readonly CreateIfcFromTemplatePath = '/project/ifc-from-template';

    /**
     * Creates an IFC record from a template.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createIfcFromTemplate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createIfcFromTemplate$Response(params: {
        context?: HttpContext;
        body: CreateIfcFromTemplateDto;
    }): Observable<StrictHttpResponse<IfcIdWithMessageEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            ProjectApiService.CreateIfcFromTemplatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<IfcIdWithMessageEntity>;
                })
            );
    }

    /**
     * Creates an IFC record from a template.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createIfcFromTemplate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createIfcFromTemplate(params: {
        context?: HttpContext;
        body: CreateIfcFromTemplateDto;
    }): Observable<IfcIdWithMessageEntity> {
        return this.createIfcFromTemplate$Response(params).pipe(
            map((r: StrictHttpResponse<IfcIdWithMessageEntity>) => r.body as IfcIdWithMessageEntity)
        );
    }

    /**
     * Path part for operation getIfcMetadata
     */
    static readonly GetIfcMetadataPath = '/project/ifc-metadata/{ifcId}';

    /**
     * Retrieves metadata from IFC.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getIfcMetadata()` instead.
     *
     * This method doesn't expect any request body.
     */
    getIfcMetadata$Response(params: {
        ifcId: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<IfcMetadataEntity>> {
        const rb = new RequestBuilder(this.rootUrl, ProjectApiService.GetIfcMetadataPath, 'get');
        if (params) {
            rb.path('ifcId', params.ifcId, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<IfcMetadataEntity>;
                })
            );
    }

    /**
     * Retrieves metadata from IFC.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getIfcMetadata$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getIfcMetadata(params: {
        ifcId: string;
        context?: HttpContext;
    }): Observable<IfcMetadataEntity> {
        return this.getIfcMetadata$Response(params).pipe(
            map((r: StrictHttpResponse<IfcMetadataEntity>) => r.body as IfcMetadataEntity)
        );
    }

    /**
     * Path part for operation getIfc3DModel
     */
    static readonly GetIfc3DModelPath = '/project/ifc-3d-model/{ifcId}';

    /**
     * Retrieves 3D model from IFC.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getIfc3DModel()` instead.
     *
     * This method doesn't expect any request body.
     */
    getIfc3DModel$Response(params: {
        ifcId: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, ProjectApiService.GetIfc3DModelPath, 'get');
        if (params) {
            rb.path('ifcId', params.ifcId, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Retrieves 3D model from IFC.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getIfc3DModel$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getIfc3DModel(params: { ifcId: string; context?: HttpContext }): Observable<void> {
        return this.getIfc3DModel$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation getIfcTemplates
     */
    static readonly GetIfcTemplatesPath = '/project/ifc-templates';

    /**
     * Retrieves IFC templates.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getIfcTemplates()` instead.
     *
     * This method doesn't expect any request body.
     */
    getIfcTemplates$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<IfcTemplateEntity>> {
        const rb = new RequestBuilder(this.rootUrl, ProjectApiService.GetIfcTemplatesPath, 'get');
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<IfcTemplateEntity>;
                })
            );
    }

    /**
     * Retrieves IFC templates.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getIfcTemplates$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getIfcTemplates(params?: { context?: HttpContext }): Observable<IfcTemplateEntity> {
        return this.getIfcTemplates$Response(params).pipe(
            map((r: StrictHttpResponse<IfcTemplateEntity>) => r.body as IfcTemplateEntity)
        );
    }

    /**
     * Path part for operation updateProject
     */
    static readonly UpdateProjectPath = '/project/{id}';

    /**
     * Updates a project.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateProject()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateProject$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateProjectDto;
    }): Observable<StrictHttpResponse<ProjectEntity>> {
        const rb = new RequestBuilder(this.rootUrl, ProjectApiService.UpdateProjectPath, 'put');
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ProjectEntity>;
                })
            );
    }

    /**
     * Updates a project.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateProject$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateProject(params: {
        id: string;
        context?: HttpContext;
        body: UpdateProjectDto;
    }): Observable<ProjectEntity> {
        return this.updateProject$Response(params).pipe(
            map((r: StrictHttpResponse<ProjectEntity>) => r.body as ProjectEntity)
        );
    }

    /**
     * Path part for operation filterProjectPhases
     */
    static readonly FilterProjectPhasesPath = '/project/{id}';

    /**
     * Filters project phases by phase type.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterProjectPhases()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterProjectPhases$Response(params: {
        id: string;
        context?: HttpContext;
        body: FilterProjectPhasesDto;
    }): Observable<
        StrictHttpResponse<{
            total?: number;
            page?: number;
            perPage?: number;
            data?: Array<DraftPhaseEntity>;
        }>
    > {
        const rb = new RequestBuilder(
            this.rootUrl,
            ProjectApiService.FilterProjectPhasesPath,
            'post'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<DraftPhaseEntity>;
                    }>;
                })
            );
    }

    /**
     * Filters project phases by phase type.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterProjectPhases$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterProjectPhases(params: {
        id: string;
        context?: HttpContext;
        body: FilterProjectPhasesDto;
    }): Observable<{
        total?: number;
        page?: number;
        perPage?: number;
        data?: Array<DraftPhaseEntity>;
    }> {
        return this.filterProjectPhases$Response(params).pipe(
            map(
                (
                    r: StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<DraftPhaseEntity>;
                    }>
                ) =>
                    r.body as {
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<DraftPhaseEntity>;
                    }
            )
        );
    }

    /**
     * Path part for operation deleteProject
     */
    static readonly DeleteProjectPath = '/project/{id}';

    /**
     * Deletes a project.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deleteProject()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteProject$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<ProjectEntity>> {
        const rb = new RequestBuilder(this.rootUrl, ProjectApiService.DeleteProjectPath, 'delete');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ProjectEntity>;
                })
            );
    }

    /**
     * Deletes a project.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deleteProject$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteProject(params: { id: string; context?: HttpContext }): Observable<ProjectEntity> {
        return this.deleteProject$Response(params).pipe(
            map((r: StrictHttpResponse<ProjectEntity>) => r.body as ProjectEntity)
        );
    }
}
