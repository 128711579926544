<div class="flex h-[var(--header-height)] items-center justify-center">
    <mat-icon
        svgIcon="keystone-logo"
        class="mx-auto !h-[70px] !w-[70px] cursor-pointer"
        (click)="navClick.emit(null)"
    ></mat-icon>
</div>

<div
    *ngLet="store.select(selectUserState) | async as userState"
    class="flex h-[calc(100%-var(--header-height))] flex-col justify-center gap-4"
>
    <ng-container *ngFor="let linkOption of navLinkOptions; let i = index">
        <a
            *ngIf="linkOption.link === RouteSegment.ContactUsPopup; else notContactUsPopup"
            class="flex flex-col items-center gap-2 py-5 text-center hover:bg-primaryHover md:flex-row md:justify-start md:gap-8 md:px-8"
            (click)="openContactUsModal()"
        >
            <mat-icon
                [svgIcon]="linkOption.icon"
                class="!h-[32px] !w-[32px] text-brand1"
            ></mat-icon>
            <span class="text-xs text-black">{{ linkOption.text$ | async }}</span>
        </a>
        <ng-template #notContactUsPopup>
            <a
                *ngIf="!linkOption.userIsAdmin || (linkOption.userIsAdmin && userState?.isAdmin)"
                [routerLink]="linkOption.link"
                (click)="navClick.emit(linkOption)"
                routerLinkActive="border-r-4 border-brand1 md:border-r-0 md:border-l-4"
                class="flex flex-col items-center gap-2 py-5 text-center hover:bg-primaryHover md:flex-row md:justify-start md:gap-8 md:px-8"
            >
                <mat-icon
                    [svgIcon]="linkOption.icon"
                    class="!h-[32px] !w-[32px] cursor-pointer text-brand1"
                ></mat-icon>
                <span class="text-xs text-black">{{ linkOption.text$ | async }}</span>
            </a>
        </ng-template>
    </ng-container>
    <div class="h-[20%]" *ngIf="!(store.select(selectUserState) | async)?.isAdmin"></div>
</div>
