// eslint-disable-next-line @typescript-eslint/naming-convention
export const environment = {
    featureFlags: {
        google3d: true
    },
    baseUrl: 'http://localhost:4200',
    apiUrl: 'https://test-api.keystone.live',
    s3Proxy: 'https://test-cdn.keystone.live',
    production: true,
    sentry_dns:
        'https://c79561bddac4e9cf2ccfbcdcc177af31@o4508202559012864.ingest.de.sentry.io/4508202561175632'
};
