<div class="flex h-screen min-h-screen items-center justify-center p-16 md:p-0">
    <div
        class="flex w-full max-w-[460px] flex-col gap-8 rounded-lg bg-white p-8 shadow-card md:h-full md:min-w-full md:rounded-none md:drop-shadow-none"
    >
        <div class="mb-4 flex items-center justify-between gap-4 md:flex-wrap-reverse">
            <!--            <mat-icon svgIcon="keystone-logo" class="mx-auto mb-4 !h-[60px] !w-[100px]"></mat-icon>-->
            <img
                alt="keystone logo"
                class="h-[64px] md:h-[48px]"
                src="assets/imgs/keystone-logo-horizontal.svg"
            />
            <app-language-switch />
        </div>

        <form
            [formGroup]="loginForm"
            (ngSubmit)="onSubmit()"
            class="flex flex-col items-center gap-4"
        >
            <input
                formControlName="email"
                type="text"
                [placeholder]="'forms.email' | transloco"
                class="w-full rounded-sm border-[1px] border-solid border-mediumgray bg-white p-3 outline-none transition focus:border-[1px] focus:border-solid focus:border-brand1"
            />
            <!--            <div class="relative">-->
            <!--                <div-->
            <!--                    *ngIf="loginForm.controls.email.touched"-->
            <!--                    class="min-h-16 leading-4 absolute -bottom-1 left-0 flex flex-col text-xs text-error"-->
            <!--                >-->
            <!--                    <span *ngIf="loginForm.controls.email.errors?.['required']">-->
            <!--                        {{ 'messages.authentication.thisInputIsRequired' | transloco }}-->
            <!--                    </span>-->
            <!--                    <span *ngIf="loginForm.controls.email.errors?.['email']">-->
            <!--                        {{ 'messages.authentication.emailIsIncorrect' | transloco }}-->
            <!--                    </span>-->
            <!--                </div>-->
            <!--            </div>-->
            <input
                formControlName="password"
                type="password"
                [placeholder]="'forms.password' | transloco"
                class="w-full rounded-sm border-[1px] border-solid border-mediumgray bg-white p-3 outline-none transition focus:border-[1px] focus:border-solid focus:border-brand1"
            />

            <!--            <div class="relative">-->
            <!--                <div-->
            <!--                    *ngIf="loginForm.controls.password.touched"-->
            <!--                    class="min-h-16 leading-4 absolute -bottom-1 left-0 flex flex-col text-xs text-error"-->
            <!--                >-->
            <!--                    <span *ngIf="loginForm.controls.password.errors?.['required']">-->
            <!--                        {{ 'messages.authentication.thisInputIsRequired' | transloco }}-->
            <!--                    </span>-->
            <!--                </div>-->
            <!--            </div>-->
            <ng-container *ngIf="(errorMessage$ | async)![0] || (errorMessage$ | async)![1]">
                <p class="text-center text-error">
                    {{ 'messages.authentication.emailOrPasswordIsIncorrect' | transloco }}
                </p>
            </ng-container>
            <button
                type="submit"
                [disabled]="(isLoading$ | async)![0] || (isLoading$ | async)![1]"
                class="btn-primary w-full"
            >
                <ng-container
                    *ngIf="!(isLoading$ | async)![0] || !(isLoading$ | async)![1]; else inProgress"
                >
                    {{ 'login.title' | transloco }}
                </ng-container>
                <ng-template #inProgress>
                    <div class="flex justify-center">
                        <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                </ng-template>
            </button>
        </form>

        <div class="horizon-divider">{{ 'login.social-prompt' | transloco }}</div>

        <div class="flex flex-col items-stretch gap-4">
            <a class="google-btn" [href]="googleLogin">
                <mat-icon svgIcon="google"></mat-icon>
                <span>{{ 'login.join-with-google' | transloco }}</span>
            </a>
            <a class="facebook-btn" [href]="facebookLogin">
                <mat-icon svgIcon="facebook"></mat-icon>
                <span>{{ 'login.join-with-facebook' | transloco }}</span>
            </a>
        </div>

        <div class="flex w-full justify-center gap-2">
            <a class="btn-link" [routerLink]="routeSegment.Register">
                {{ 'login.register' | transloco }}
            </a>
            <span>|</span>
            <a class="btn-link" [routerLink]="routeSegment.ForgotPassword">
                {{ 'forgot-password.link' | transloco }}
            </a>
        </div>
    </div>
</div>
