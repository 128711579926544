/* tslint:disable */
/* eslint-disable */
import { AssetEntity } from './asset-entity';
import { BuildingStructureEntity } from './building-structure-entity';
import { CertificateEntity } from './certificate-entity';
import { FeatureEntity } from './feature-entity';
import { SupplierEntity } from './supplier-entity';
export interface IBuildingStructureProductEntity {
    buildingStructure: BuildingStructureEntity;
    certificates: Array<CertificateEntity>;
    deletedAt: null | string;
    descriptionTranslation: {};
    features: Array<FeatureEntity>;
    id: string;
    images: Array<AssetEntity>;
    multiplier: number;
    name: string;
    nameTranslation: {};
    recommended: boolean;
    score_comfort: number;
    score_durability: number;
    score_ecological: number;
    score_mobility: number;
    score_price: number;
    score_speed: number;
    structureId: string;
    supplier: SupplierEntity;
    supplierId: string;
}
export class BuildingStructureProductEntity implements IBuildingStructureProductEntity {
    buildingStructure!: BuildingStructureEntity;
    certificates!: Array<CertificateEntity>;
    deletedAt!: null | string;
    descriptionTranslation!: {};
    features!: Array<FeatureEntity>;
    id!: string;
    images!: Array<AssetEntity>;
    multiplier!: number;
    name!: string;
    nameTranslation!: {};
    recommended!: boolean;
    score_comfort!: number;
    score_durability!: number;
    score_ecological!: number;
    score_mobility!: number;
    score_price!: number;
    score_speed!: number;
    structureId!: string;
    supplier!: SupplierEntity;
    supplierId!: string;
    constructor(data: IBuildingStructureProductEntity) {
        Object.assign(this, data);
    }
}
